






































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VModal from "vue-js-modal";
import { namespace } from "vuex-class";

import { OrgUser } from "@/types";
import { Loader, SvgIcon, NotificationModal } from "@components";

Vue.use(VModal);

const userModule = namespace("user");

@Component({
  components: {
    Loader,
    SvgIcon,
    NotificationModal,
  },
})
export default class ApproveUserModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;
  @userModule.Action("approveUser") approveUser!: (
    invitationID: string
  ) => Record<string, any>;

  loading = false;
  user = {} as OrgUser;
  notifText = "";
  notifType = "success";
  error = "";
  showNotifModal = false;

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  _hideApproveUserModal() {
    this.$modal.hide("approve-user-modal");
  }

  closeNotificationModal() {
    this.showNotifModal = false;
  }

  async onConfirmApproval() {
    this.loading = true;
    const invitationID = this.user.id;

    try {
      await this.approveUser(invitationID);
      this._hideApproveUserModal();
      this.notifText = "User has been approved";
      this.showNotifModal = true;
      this.$emit("fetch-users");
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }
}
