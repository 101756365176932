





















































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";

import { UserRole, OrgUser } from "@/types";
import { Dropdown, CustomInput, Loader, SvgIcon } from "@components";
import { getRoles } from "@/utils/common";
import { User } from "@/services";

Vue.use(VModal);

interface RolesDropdown {
  label: string;
  value: UserRole;
}

const configModule = namespace("config");
const authModule = namespace("auth");

@Component({
  components: {
    Loader,
    Dropdown,
    CustomInput,
    SvgIcon,
  },
})
export default class EditDetailsModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;
  @configModule.State("profileURL") profileURL!: string;
  @authModule.Getter("accessToken") accessToken!: string;

  loading = false;
  roles = [] as RolesDropdown[];
  user = {} as OrgUser;
  error = "";

  created() {
    this._initRoles();
  }

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  get updateButtonText(): string {
    return this.loading ? "Updating User" : "Update";
  }

  _hideEditDetailsModal() {
    this.$modal.hide("edit-details-modal");
  }

  _initRoles() {
    const roles = getRoles();

    this.roles = Object.keys(roles).map((role) => ({
      label: role,
      value: roles[role],
    }));
  }

  async _editUserInfo() {
    this.loading = true;

    const payload = {
      id: this.user.id,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
    };

    try {
      await new User(this.profileURL, this.accessToken).editUserInfo(payload);
    } catch (error) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  }
}
