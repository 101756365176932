



























































import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";

import { CustomTable, Dropdown, CustomInput } from "@components";
import { OrgUser, UserInfo } from "@/types";
import { RoleType } from "@/proto/profile/all_pb";
import { getRoleLabel } from "@/utils/common";

import UserRow from "./components/UserRow.vue";
import EditDetailsModal from "./components/EditDetailsModal.vue";
import ResendInviteModal from "./components/ResendInviteModal.vue";
import BlockUserModal from "./components/BlockUserModal.vue";
import ApproveUserModal from "./components/ApproveUserModal.vue";
import RevokeInviteModal from "./components/RevokeInviteModal.vue";
import SendInviteModal from "./components/SendInviteModal.vue";
import RejectUserModal from "./components/RejectUserModal.vue";
import DeleteUserModal from "./components/DeleteUserModal.vue";

Vue.use(VModal);

const userModule = namespace("user");
const orgModule = namespace("org");

@Component({
  components: {
    CustomTable,
    UserRow,
    EditDetailsModal,
    ResendInviteModal,
    BlockUserModal,
    RevokeInviteModal,
    CustomInput,
    Dropdown,
    ApproveUserModal,
    SendInviteModal,
    RejectUserModal,
    DeleteUserModal,
  },
})
export default class Users extends Vue {
  @userModule.Getter("userInfoExists") userInfoExists!: boolean;
  @userModule.Getter("currentUserInfo") user!: UserInfo;
  @userModule.Getter("isBankAdmin") isBankAdmin!: boolean;
  @userModule.Getter("isAccountAdmin") isAccountAdmin!: boolean;
  @orgModule.Action("getOrgUsers") getOrgUsers!: () => Array<OrgUser>;

  searchTerm = "";
  isInviteUserModalVisible = false;
  sortBy = "role";
  selectedUser = {};
  roleOptions: Array<any> = [];
  users: Array<OrgUser> = [];
  isReady = false;

  get COLUMNS() {
    const columns = [
      {
        field: "name",
        label: "Name",
        sortable: true,
        width: "50px",
      },
      {
        field: "email",
        label: "Email",
        sortable: true,
        width: "50px",
      },
      {
        field: "dateInvited",
        label: "Date Invited",
        dataType: "date",
        sortable: true,
        defaultSort: true,
        filter: true,
        width: "150px",
      },
      {
        field: "role",
        label: "Role",
        dataType: "checkbox",
        sortable: false,
        filter: true,
        options: this.roleOptions,
        width: "70px",
      },
      {
        field: "status",
        label: "Status",
        dataType: "select",
        sortable: false,
        options: this.statusOptions,
        filter: true,
        width: "70px",
      },
      {
        field: "options",
        label: "",
        sortable: false,
        width: "20px",
      },
    ];

    if (this.isBankAdmin) {
      columns.splice(2, 0, {
        field: "corporateID",
        label: "Corporate ID",
        sortable: true,
        width: "50px",
      });
    }

    return columns;
  }

  private state: any = {
    new: true,
    userList: [],
    page: 1,
    limit: 10,
    searchQuery: "",
  };

  get statusOptions() {
    return [
      {
        label: "Invited",
        value: "invited",
      },
      {
        label: "Active",
        value: "active",
      },
    ];
  }

  updateRoleOptions() {
    if (this.isBankAdmin) {
      this.roleOptions = [
        {
          label: "Bank Admin",
          value: getRoleLabel(RoleType.BANKADMINISTRATOR),
        },
        {
          label: "Account Admin",
          value: getRoleLabel(RoleType.ACCOUNTADMINISTRATOR),
        },
        {
          label: "Content Admin",
          value: getRoleLabel(RoleType.CONTENTADMIN),
        },
      ];
    }

    this.roleOptions = [
      {
        label: "Account Admin",
        value: getRoleLabel(RoleType.ACCOUNTADMINISTRATOR),
      },
      {
        label: "Checker",
        value: getRoleLabel(RoleType.CHECKER),
      },
      {
        label: "Maker",
        value: getRoleLabel(RoleType.MAKER),
      },
    ];
  }

  // mounted() {
  //   this.generateUserList();
  // }

  async generateUserList() {
    this.users = await this.getOrgUsers();

    if (this.isBankAdmin) {
      this.state.userList = this.users
        .map((user) => {
          return {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            corporateID: user.corporateID,
            dateInvited: user.dateInvited,
            role: getRoleLabel(user.role),
            status: user.status,
          };
        })
        .reverse();
    } else {
      this.state.userList = this.users
        .map((user) => {
          return {
            id: user.id,
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            dateInvited: user.dateInvited,
            role: getRoleLabel(user.role),
            status: user.status,
          };
        })
        .reverse();
    }
  }

  @Watch("userInfoExists", { immediate: true })
  watchUserInfoExists(infoExists: boolean) {
    if (infoExists) {
      this.updateRoleOptions();
      this.isReady = true;
      this.generateUserList();
    }
  }

  _showActionModal(selectedUser: OrgUser, modalName: string) {
    this.selectedUser = selectedUser;
    this.$modal.show(modalName);
  }

  onBlockUser() {
    this.generateUserList();
  }
}
