





























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VModal from "vue-js-modal";

import { OrgUser } from "@/types";
import { Loader, SvgIcon } from "@components";

Vue.use(VModal);

@Component({
  components: {
    Loader,
    SvgIcon,
  },
})
export default class RevokeInviteModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;

  loading = false;
  user = {} as OrgUser;
  error = "";

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  get buttonText(): string {
    return this.loading ? "REVOKING INVITE" : "CONFIRM";
  }

  _hideRevokeInviteModal() {
    this.$modal.hide("revoke-invite-modal");
  }
}
