


































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VModal from "vue-js-modal";
import { namespace } from "vuex-class";

import { OrgUser } from "@/types";
import { Loader, SvgIcon, NotificationModal } from "@components";

Vue.use(VModal);

const userModule = namespace("user");

@Component({
  components: {
    Loader,
    SvgIcon,
    NotificationModal,
  },
})
export default class SendInviteModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;
  @userModule.Action("sendInvite") sendInvite!: (invitationID: string) => void;

  loading = false;
  user = {} as OrgUser;
  error = "";
  notifText = "";
  notifType = "success";
  showNotifModal = false;

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  get buttonText(): string {
    return this.loading ? "SENDING" : "CONFIRM";
  }

  _hideSendInviteModal() {
    this.$modal.hide("send-invite-modal");
  }

  closeNotificationModal() {
    this.showNotifModal = false;
  }

  async onSendInvite() {
    this.loading = true;
    const invitationID = this.user.id;

    try {
      await this.sendInvite(invitationID);
      this._hideSendInviteModal();
      this.notifText = "User has been successfully invited!";
      this.showNotifModal = true;
      this.$emit("fetch-users");
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }
}
