




























































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VModal from "vue-js-modal";
import { namespace } from "vuex-class";

import { OrgUser } from "@/types";
import { Loader, SvgIcon, NotificationModal } from "@components";
import { User } from "@/services";

Vue.use(VModal);

const configModule = namespace("config");
const authModule = namespace("auth");

@Component({
  components: {
    Loader,
    SvgIcon,
    NotificationModal,
  },
})
export default class BlockUserModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;
  @configModule.State("profileURL") profileURL!: string;
  @authModule.Getter("accessToken") accessToken!: string;

  loading = false;
  user = {} as OrgUser;
  error = "";

  // notification data
  fullName = "";
  modalType = "block";
  showNotificationModal = false;

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  _hideBlockUserModal() {
    this.$modal.hide("block-user-modal");
  }

  closeNotificationModal() {
    this.showNotificationModal = false;
  }

  async _blockUser() {
    this.loading = true;
    const userId: OrgUser["id"] = this.user.id;
    let response: any;

    try {
      response = await new User(this.profileURL, this.accessToken).blockUser(
        userId
      );
    } catch (error) {
      this.error = error.message;
    } finally {
      this.loading = false;
    }

    const firstName = response.profile.personalinfo.firstname;
    const lastName = response.profile.personalinfo.lastname;
    this.fullName = `${firstName} ${lastName}`;
    this._hideBlockUserModal();
    this.$emit("on-block-user");

    this.showNotificationModal = true;
  }
}
