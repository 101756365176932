








































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import VModal from "vue-js-modal";
import { namespace } from "vuex-class";

import { OrgUser } from "@/types";
import { Loader, SvgIcon, NotificationModal } from "@components";

Vue.use(VModal);

const userModule = namespace("user");

@Component({
  components: {
    Loader,
    SvgIcon,
    NotificationModal,
  },
})
export default class ResendInviteModal extends Vue {
  @Prop({ type: Object, default: {} }) selectedUser!: OrgUser;
  @userModule.Action("resendInvite") resendInvite!: (
    invitationID: string
  ) => void;

  loading = false;
  user = {} as OrgUser;
  error = "";
  mustShowNotif = false;

  @Watch("selectedUser", { immediate: true })
  onSelectedUserChanged(selectedUser: OrgUser) {
    this.user = selectedUser;
    this.error = "";
  }

  get buttonText(): string {
    return this.loading ? "RE-SENDING" : "CONFIRM";
  }

  _hideResendInviteModal() {
    this.$modal.hide("resend-invite-modal");
  }

  closeNotif() {
    this.mustShowNotif = false;
  }

  showNotif() {
    this.mustShowNotif = true;
  }

  async onResendInvite() {
    this.loading = true;
    const invitationID = this.user.id;

    try {
      await this.resendInvite(invitationID);
      this._hideResendInviteModal();
      this.showNotif();
      this.$emit("fetch-users");
    } catch (error) {
      const err = error as Error;
      this.error = err.message;
    } finally {
      this.loading = false;
    }
  }
}
