

















































































































































import { Vue, Component, Prop, Emit } from "vue-property-decorator";
import VTooltip from "v-tooltip";
import moment from "moment";

import { SvgIcon } from "@/components";

Vue.use(VTooltip);

@Component({
  components: {
    SvgIcon,
  },
})
export default class UserRow extends Vue {
  @Prop({ type: Object, default: {} }) row!: Record<string, any>;
  @Prop({ type: String, default: "" }) field!: string;
  @Prop({ default: false }) isBankAdmin!: boolean;
  @Prop({ default: false }) isAccountAdmin!: boolean;

  get dateInvited(): string {
    if (!this.row.dateInvited) return "N/A";
    return moment
      .unix(this.row.dateInvited)
      .format("DD MMM YYYY\n kk:mm [GMT]");
  }

  get shouldShowRevoke() {
    if (this.row.status === "pending") {
      return this.isAccountAdmin ? true : false;
    }

    if (this.row.status === "invited") {
      return true;
    }

    return false;
  }

  get shouldShowBlock() {
    return this.row.status === "active";
  }

  get shouldShowUnblock() {
    return this.isBankAdmin && this.row.status === "blocked";
  }

  get shouldShowRequestUnblock() {
    return this.isAccountAdmin && this.row.status === "blocked";
  }

  get shouldShowResendInvite() {
    return this.row.status === "invited";
  }

  get shouldShowDelete() {
    return this.isBankAdmin;
  }

  get shouldShowApprove() {
    return this.isBankAdmin && this.row.status === "pending";
  }

  get shouldShowSendInvite() {
    return this.isAccountAdmin && this.row.status === "approved";
  }

  @Emit("onResendUserInvite")
  onResendUserInvite() {
    return this.row;
  }

  @Emit("onRemoveUser")
  onRemoveUser() {
    return this.row;
  }

  @Emit("onRevokeUserInvite")
  onRevokeUserInvite() {
    return this.row;
  }
}
